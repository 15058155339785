import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import Footer from "../components/Footer";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slider from '@mui/material/Slider';
import Topbar from "../components/Topbar";

const Test = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isanswerType, setIsAnswerType] = useState(0);
    const [question, setQuestion] = useState([]);
    const [gender, setGender] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [showquestion, setShowquestion] = useState(false);
    const [ansArray, setAnsArray] = useState({});
    const [res, setRes] = useState({});
    const [currentAnswerText, setCurrentAnswerText] = useState("");
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("success");
    const [message, setMessage] = useState("message");
    const [validate, setValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [slider, setSlider] = useState(false)
    const [rangeVar1, setRangeVar1] = useState("0")
    const [rangeVar2, setRangeVar2] = useState("0")
    const [rangeVar3, setRangeVar3] = useState("0")
    const [rangeVar4, setRangeVar4] = useState("0")
    const [sleepmax, setSleepmax] = useState(0)
    const [stressmax, setStressmax] = useState(0)
    const [exmax, setExmax] = useState(0)
    const [diet, setDiet] = useState(false)
    const [stress, setStress] = useState(false)
    const [sleep, setSleep] = useState(false)
    const [exercise, setExcercise] = useState(false)

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const marks1 = [
        {
            value: 0,
            label: '0%',
        },

        ,
        {
            value: 100,
            label: '100%',
        },
    ];
    const marks2 = [
        {
            value: 0,
            label: '0%',
        },


        ,
        {
            value: 100,
            label: '100%',
        },
    ];
    const marks3 = [
        {
            value: 0,
            label: '0%',
        },


        ,
        {
            value: 100,
            label: '100%',
        },
    ];
    const marks4 = [
        {
            value: 0,
            label: '0%',
        },


        ,
        {
            value: 100,
            label: '100%',
        },
    ];

    function valuetext1(value) {
        return `${value}`;
    }
    function valuetext2(value) {
        return `${value}`;
    }
    function valuetext3(value) {
        return `${value}`;
    }
    function valuetext4(value) {
        return `${value}`;
    }
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    useEffect(() => {
        let authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setLoading(true);
            fetch("https://med.rentproperties.in/questionList", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ genderType: "Male" }),
            }).then((response) => {
                setLoading(false);
                setQuestion(response);
            });
        } else {
            console.log("login");
            let gender = localStorage.getItem("gender");
            console.log(gender);
            setLoading(true);
            fetch("https://med.rentproperties.in/questionList", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ genderType: gender }),
            })
                .then((response) => response.json())

                .then((response) => {
                    setLoading(false);
                    setQuestion(response);
                    console.log("coming data", response)
                });
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log('ans array changed');
    }, [ansArray]);


    const fun = async () => {
        return new Promise((resolve, reject) => {
            console.log(ansArray, "mmsms")
            delete ansArray["oldObj"]
            let objects = Object.keys(ansArray);
            // objects.map(ele=>)
            // objects.map((ele, index) => {

            let ar = []
            objects.map(ele => {
                ar.push(ansArray[ele])
            })
            console.log(ar)

            fetch("https://med.rentproperties.in/answer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
                body: JSON.stringify(ar),
            }).then((response) => response.json())
                .then((response) => {
                    if (!_.isEmpty(response)) {
                        console.log("ehi", response);
                        setRes(response);

                        return resolve({ status: true, data: response })


                        // setTimeout(() => 3000)
                    }
                });
            // })
        });
    }
    const show = () => {
        setShowquestion(true);
        setGender(true);
        console.log(question);
    };
    const handleOnSubmitClick = async () => {
        console.log("this is answer array", ansArray)
        let total = parseFloat(rangeVar1) + parseFloat(rangeVar2) + parseFloat(rangeVar3) + parseFloat(rangeVar4)


        console.log("total",)
        console.log("diet", diet)
        console.log("stress", stress)
        console.log("sleep", sleep)
        console.log("exercise", exercise)
        if (!diet || stress || !sleep || !exercise) {
            if (total !== 100) {
                setType("error");
                setMessage("The Overall percent sum should be 100%");
                setOpen(true);
            } else {
                setType("info");
                setMessage("Please Wait For Results");
                setOpen(true);

                let dat = await fun()
                console.log("inside 5", dat)
                if (dat.status) {

                    console.log("inside 1", dat)
                    navigate("/result", { state: { res: dat.data, stress: stress, diet: diet, exercise: exercise, sleep: sleep } })
                    setOpen(false);


                }
            }
        } else {
            setType("info");
            setMessage("Please Wait For Results");
            setOpen(true);
            let dat = await fun()
            console.log("inside 5", dat)
            if (dat) {
                console.log("inside 5")

                navigate("/result", { state: { res: dat.data, stress: stress, diet: diet, exercise: exercise, sleep: sleep } })
                setOpen(false);


            }

        }

        // constdelete ansArray[Object.keys(ansArray)[Object.keys(ansArray).length - 1]];
    };
    const handleOnNextClick = (e, ind) => {
        console.log("answer array ", ansArray);
        console.log("question current ", question[currentQuestion + 1])
        if (!ansArray[question[currentQuestion + 1]?.id]) {
            console.log("answer not there")
            setCurrentAnswerText("")
        } else {
            console.log("answer already there")
            setCurrentAnswerText(ansArray[question[currentQuestion + 1]?.id]?.answer)
        }
        console.log("this is array", ind)

        if (!ansArray[question[ind].id + 1]) {
            setValidate(false)
        }
        if (currentQuestion != question.length - 1) {
            let oldObj = ansArray;
            let currentQuestionObj = question[currentQuestion];
            setAnsArray(oldObj);
            console.log("this is array25", oldObj[question[ind].id]?.answer)
            if (parseInt(oldObj[question[ind].id]?.answer) > 0 && parseInt(oldObj[question[ind].id]?.answer) < 101) {
                console.log("mmmmkkk")
                setCurrentAnswerText(oldObj[question[ind].id]?.answer)
            }
            console.log("this is old array", oldObj)
            setCurrentQuestion(currentQuestion + 1);
        }
    };
    const handleOnPrevClick = (e, ind) => {
        console.log("this is preve", ansArray)

        if (ansArray[question[ind].id - 1]) {

            setValidate(true)
        }
        if (currentQuestion != 0) {
            let currentAnswer = ansArray[question[currentQuestion - 1].id].answer;
            setCurrentAnswerText(currentAnswer);
            setCurrentQuestion(currentQuestion - 1);
        }
    };
    const submitQuestion = async (e) => {
        e.preventDefault();
        axios
            .post("https://med.rentproperties.in/answer", {
                body: {
                    Authorization: localStorage.getItem("genderType"),
                },
            })
            .then((response) => alert(response))
            .catch((error) => console.log(error));
    };
    const onchangeAnswer = (e, id, quid, qtype) => {
        console.log("hjekjh", qtype, e.target.getAttribute("data-answertype"))
        if (qtype === "Diet" && e.target.getAttribute("data-answertype") === "Yes") {
            console.log("inside")
            setDiet(true)
        } else if (qtype === "Diet" && e.target.getAttribute("data-answertype") === "No") {
            setDiet(false)
        }
        if (qtype === "Sleep" && e.target.getAttribute("data-answertype") === "Yes") {
            console.log("inside")
            setSleep(true)
        } else if (qtype === "Sleep" && e.target.getAttribute("data-answertype") === "No") {
            setSleep(false)
        }
        if (qtype === "Stress" && e.target.getAttribute("data-answertype") === "Yes") {
            console.log("inside")
            setStress(true)
        } else if (qtype === "Stress" && e.target.getAttribute("data-answertype") === "No") {
            console.log("inside false")
            setStress(false)
        }
        if (qtype === "Excercise" && e.target.getAttribute("data-answertype") === "Yes") {

            setExcercise(true)
        } else if (qtype === "Excercise" && e.target.getAttribute("data-answertype") === "No") {
            setExcercise(false)
        }
        console.log('radio button on changed clicked');
        setCurrentAnswerText(e.target.value);
        setValidate(true)
        let oldObj = ansArray;
        oldObj[id.toString()] = {
            user_id: localStorage.getItem("id"),
            question_id: id.toString(),
            questionnaire_id: quid.toString(),
            answer: e.target.type == 'number' ? e.target.value : (e.target.getAttribute("data-answertype") == "Yes" && e.target.checked ? 'Yes' : 'No')
        };
        console.log('old obj ', oldObj);
        console.log(ansArray);
        setAnsArray({ ...ansArray, oldObj });
    };
    const submitGender = async (e) => {
        e.preventDefault();
        console.log(gender, "this is gender");
        // return 0;
        fetch("https://med.rentproperties.in/questionList", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ genderType: gender }),
        })
            .then((response) => response.json())
            .then((response) => {
                // console.log();
                setQuestion(response);
                console
                    .log(response, "this is res")
                    .then((response) => console.log(response))

                    .catch((error) => console.log(error));
            });
    };
    useEffect(() => {
        let authToken = localStorage.getItem("auth_token", "genderType");
        if (!authToken) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
        let genderType = localStorage.getItem("genderType", "authToken");
        const show = () => {
            if (!genderType) {
                setShowquestion(false);
            } else {
                setShowquestion(true);
            }
        };

    }, []);
    return (
        <>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <Topbar />
            <Navbar />
            {_.isEmpty(res) ?
                <div className="container-fluid  " style={{
                    height: "700px", display: "grid", alignItems: "center", backgroundImage: 'url(	http://localhost:3000/static/media/hero-bg.780df2f268b43ea6e3aa.jpg)',
                    backgroundRepeat: 'no-repeat', backgroundAttachment: "fixed"
                }}>
                    <div className="row" style={{ backgroundColor: "rgba('0,0,0,0.5')" }}>
                        <div className="col-sm-12 " style={{}}>
                            <div className="row ">
                                {isLoggedIn ? (
                                    <div className="row">

                                        {loading ? <><div class="spinner-border ms-auto me-auto mt-5" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div></> : !slider ?
                                            <>

                                                <div className="col-sm-4  py-4 bg-white p-4 mt-5 pt-5 ms-auto me-auto shadow " style={{ height: "250px", display: "grid", alignItems: "center" }}>
                                                    <h5 className="mb-3" style={{ fontSize: "18px" }}>{question[currentQuestion]?.question}</h5>
                                                    {question[currentQuestion]?.answerType == 0 ?
                                                        (

                                                            <div className="col-sm-12">
                                                                <div>
                                                                    {/* <h1 >it's upper</h1> */}
                                                                    <TextField
                                                                        id="standard-basic"
                                                                        label="Answer"
                                                                        className="w-100 border-0"
                                                                        variant="standard"
                                                                        required
                                                                        type="number"
                                                                        value={currentAnswerText} onChange={(e) => {
                                                                            onchangeAnswer(
                                                                                e,
                                                                                question[currentQuestion]?.id,

                                                                                question[currentQuestion]?.questionnaire_id,
                                                                                question[currentQuestion]?.questionType
                                                                            );
                                                                            // console.log(currentAnswerText, "ye kya h")
                                                                        }}
                                                                    />
                                                                    <br></br>
                                                                    <br></br>
                                                                    <Button
                                                                        variant="contained"
                                                                        className=""
                                                                        onClick={e => handleOnPrevClick(e, currentQuestion)}
                                                                    >
                                                                        <i class="fa fa-arrow-left"></i>  &nbsp; Prev
                                                                    </Button>
                                                                    {question.length - 1 === currentQuestion ? (
                                                                        <div className="text-right w-100">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                className="ms-3"
                                                                                onClick={e => setSlider(true)}
                                                                            >
                                                                                Next&nbsp;<i class="fa fa-arrow-right"></i>
                                                                            </Button>
                                                                        </div>

                                                                    ) :
                                                                        validate ? (
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                className=" ms-3 "
                                                                                style={{ marginleft: "200px" }}
                                                                                onClick={e => handleOnNextClick(e, currentQuestion)}
                                                                            >
                                                                                Next &nbsp;<i class="fa fa-arrow-right"></i>
                                                                            </Button>) : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="col-sm-6 mt-2  mb-5"
                                                                style={{ height: "200px" }}
                                                            >{console.log("this is it", isanswerType)}
                                                                {/* <h5>{question[currentQuestion]?.question}</h5> */}
                                                                <div className="radio-div d-flex text-center">
                                                                    <div class="form-check mb-3 ">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={ansArray[question[currentQuestion]?.id]?.answer == "Yes"}
                                                                            name="flexRadioDefault1" data-answertype="Yes"
                                                                            onChange={(e) => {
                                                                                onchangeAnswer(
                                                                                    e,
                                                                                    question[currentQuestion]?.id,

                                                                                    question[currentQuestion]?.questionnaire_id,
                                                                                    question[currentQuestion]?.questionType
                                                                                );
                                                                            }} id="flexRadioDefault" />
                                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check ms-3">
                                                                        <input class="form-check-input" type="radio"

                                                                            checked={ansArray[question[currentQuestion]?.id]?.answer == "No"}

                                                                            name="flexRadioDefault" data-answertype="No" onChange={(e) => {
                                                                                onchangeAnswer(
                                                                                    e,
                                                                                    question[currentQuestion]?.id,
                                                                                    question[currentQuestion]?.questionnaire_id,
                                                                                    question[currentQuestion]?.questionType
                                                                                );
                                                                            }} id="flexRadioDefault" />
                                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                                <Button
                                                                    variant="contained"
                                                                    className=" "
                                                                    onClick={e => handleOnPrevClick(e, currentQuestion)}
                                                                >
                                                                    <i className="fa fa-arrow-left"></i>&nbsp; Prev
                                                                </Button>
                                                                {question.length - 1 === currentQuestion ? (
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        className="ms-3"
                                                                        onClick={e => setSlider(true)}
                                                                        style={{ marginleft: "200px" }}
                                                                    >
                                                                        Next<i class="fa fa-arrow-right"></i>
                                                                    </Button>

                                                                ) :
                                                                    validate ? (
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            className="ms-3"
                                                                            onClick={e => handleOnNextClick(e, currentQuestion)}
                                                                            style={{ marginleft: "200px" }}
                                                                        >
                                                                            Next &nbsp;<i class="fa fa-arrow-right"></i>
                                                                        </Button>) : ""
                                                                }
                                                            </div>

                                                        )}
                                                </div>

                                            </> : <div className="container  bg-white">
                                                <h4 className=" text-center" style={{ color: "black" }}>{sleep && exercise && diet && !stress ? "👏 Thank you for the information ...please submit" : "🤔 What do you think cause your problems"}</h4>
                                                <div className="row mt-5">
                                                    <div className="col-sm-6 mt-5">
                                                        <div className="row ">
                                                            {!diet ?
                                                                <div className="row">
                                                                    <div className="col-sm-7  p-3">
                                                                        <label style={{ fontSize: "13px" }}>Diet</label>
                                                                        <Slider
                                                                            aria-label="Custom marks"
                                                                            defaultValue={0}
                                                                            getAriaValueText={valuetext1}
                                                                            onChange={e => {
                                                                                setRangeVar1(e.target.value)
                                                                                setSleepmax(100 - e.target.value)
                                                                            }}
                                                                            step={1}
                                                                            valueLabelDisplay="auto"
                                                                            marks={marks1}
                                                                            style={{ color: "#7CB9E8" }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-2 text-center">
                                                                        <label htmlFor="" style={{ lineHeight: "110px", color: "green" }}>{`${rangeVar1}%`}</label>
                                                                    </div>
                                                                </div>
                                                                : ""}
                                                            {!sleep ?
                                                                <div className="row">
                                                                    <div className="col-sm-7   p-3">
                                                                        <label style={{ fontSize: "13px" }}>Sleep</label>
                                                                        <Slider
                                                                            aria-label="Custom marks"
                                                                            defaultValue={0}
                                                                            getAriaValueText={valuetext2}
                                                                            onChange={e => {
                                                                                setRangeVar2(e.target.value)
                                                                            }}
                                                                            step={1}
                                                                            valueLabelDisplay="auto"
                                                                            marks={marks2}
                                                                            style={{ color: "#7CB9E8" }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-2 text-center"><label htmlFor="" style={{ lineHeight: "110px", color: "green" }}>{`${rangeVar2}%`}</label></div>
                                                                </div>
                                                                : ""}
                                                            {exercise ?
                                                                ""
                                                                :
                                                                <div className="row">
                                                                    <div className="col-sm-7  p-3">
                                                                        <label style={{ fontSize: "13px" }}>Physical Activity</label>
                                                                        <Slider
                                                                            aria-label="Custom marks"
                                                                            defaultValue={0}
                                                                            getAriaValueText={valuetext3}
                                                                            onChange={e => setRangeVar3(e.target.value)}
                                                                            step={1}
                                                                            valueLabelDisplay="auto"
                                                                            marks={marks3}
                                                                            style={{ color: "#7CB9E8" }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-2 text-center"><label htmlFor="" style={{ lineHeight: "110px", color: "green" }}>{`${rangeVar3}%`}</label></div>
                                                                </div>}
                                                            {stress ?
                                                                <div className="row">
                                                                    <div className="col-sm-7  p-3">
                                                                        <label className="p-0" style={{ fontSize: "13px" }}>Psychology</label>
                                                                        <Slider
                                                                            aria-label="Custom marks"
                                                                            defaultValue={0}
                                                                            getAriaValueText={valuetext4}
                                                                            onChange={e => setRangeVar4(e.target.value)}
                                                                            step={1}
                                                                            valueLabelDisplay="auto"
                                                                            marks={marks4}
                                                                            style={{ color: "#7CB9E8" }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-2 text-center">      <label htmlFor="" className="" style={{ lineHeight: "110px", color: "green" }}>{`${rangeVar4}%`}</label></div>
                                                                </div>
                                                                : ""}
                                                            <div>
                                                                <Button onClick={handleOnSubmitClick} className="  px-4" variant="contained" style={{ backgroundColor: "#7CB9E8" }}>Submit &nbsp;<i className="fa fa-arrow-right"></i> </Button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }:
                                    </div>
                                ) : (
                                    <div className="col-sm-12 ms-auto me-auto bg-white  text-center m-0 p-5" style={{ height: "700px" }}>
                                        <img
                                            src="https://img.freepik.com/premium-vector/login-access-denied-vector-illustration-system-refuses-password-error-entry-computer-device-showing-user-does-have-permission-website-mobile-development_2175-1262.jpg"
                                            alt=""
                                            className="mt-5 p-5" />
                                        <br></br>

                                        <Link to="/login">
                                            <Button variant="text" className="" style={{ fontSize: "19px" }}>Login &nbsp;<i className="fa fa-arrow-right"></i> </Button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            <Footer />
        </>
    );
};
export default Test;
