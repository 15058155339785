import React from 'react'

const Faq = () => {
    return (
        <>

            <section id="faq" class="faq section-bg">
                <div class="container">

                    <div class="section-title">
                        <h2>Frequently Asked Questions</h2>
                        <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                    </div>

                    <div class="faq-list">
                        <ul>
                            <li data-aos="fade-up">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">What Are Your Medical and Surgical Histories?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                    <p>
                                        The patient health record will be more complete and valuable if you know whether they have ever been hospitalized, treated for a chronic condition, had medical tests, or had surgery. Even if an adult patient had surgery or some other treatment as a child, it's important to know about it when creating a treatment plan and delivering healthcare.
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="100">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">What Prescription and Non-Prescription Medications Do You Take?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Some people think that over-the-counter medications don't count, or that herbal supplements don't matter. Make it clear to new patients that the physician needs to know not only about any prescription medications they take, but also about over-the-counter medications, vitamins, and herbal supplements.
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="200">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">What Allergies Do You Have? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        In addition to knowing whether a new patient has seasonal or food allergies, doctors need to know if they have any drug allergies, a latex allergy, or a serious reaction to bee stings, for example. EHRs are terrific for using this information to alert doctors and nurses of potential drug interactions and allergies so allergens can be avoided.
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="300">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">What is Your Smoking, Alcohol, and Illicit Drug Use History? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        If you make it clear up front that you take patient confidentiality seriously and protect their information at all times, they're more likely to be forthright about whether they use tobacco products, drink alcohol regularly, or use (or have used) illicit substances. Answers to these questions can make a difference when it comes to diagnosing and treating health conditions, and reassuring patients of their privacy helps elicit honesty from the start.
                                    </p>
                                </div>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="400">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">Have You Served in the Armed Forces? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        It's important to know if a new patient has served in the military, particularly if they participated in one or more combat tours. This can help you learn more about physical trauma, potential exposure to toxins, and possible mental health issues like post-traumatic stress disorder (PTSD) so that diagnosis and treatment options can be tailored to the patient's needs.
                                    </p>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Faq