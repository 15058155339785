import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="/">
                Integrated Medicine
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignInSide() {
    const [number, setNumber] = useState("")
    const [password, setPasword] = useState("")
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("success");
    const [message, setMessage] = useState("message");
    const [loading, setLoading] = useState(false);


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const loginuser = async (e) => {
        e.preventDefault()
        setLoading(true);

        const login = await fetch("https://med.rentproperties.in/login", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mobile: number, password: password })
        })
        let res = await login.json()
        try {
            console.log(login)
            if (login.status == 200) {
                localStorage.setItem("auth_token", res.token);
                localStorage.setItem("gender", res.user.gender);
                localStorage.setItem("id", res.user.id);
                localStorage.setItem("mobile", res.user.mobile);
                localStorage.setItem("fullName", res.user.fullName);
                setLoading(false);
                setType("success");
                setMessage("You Are Login SuccessFully");
                setOpen(true);
                setTimeout(() => { navigate('/') }, 1000)
            } else {
                setLoading(false);
                setType("error");
                setMessage("Wrong credentials");
                setOpen(true);
                setTimeout(() => { navigate('/login') }, 1000)
            }
        }
        catch (error) {
            setLoading(false);
            console.log(error)
            setError(res[Object.keys(res)[0]] + [Object.keys(res)[0]][0])
        }
    }
    return (
        <>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: 'url(	http://localhost:3000/static/media/hero-bg.780df2f268b43ea6e3aa.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                {/* <LockOutlinedIcon />     */}
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <Box component="form" noValidate onSubmit={loginuser} sx={{ mt: 3 }} className="col-sm-8">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Enter Number"
                                    name="email"
                                    autoComplete="email"
                                    type={"number"}
                                    autoFocus
                                    onChange={e => setNumber(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={e => setPasword(e.target.value)}

                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className='p-2'
                                    sx={{ mt: 3, mb: 2, backgroundColor: "#3291e6" }}

                                >
                                    {loading ? <><div class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>&nbsp;Loading...</> : <>Login</>}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        {/* <Link to={"/"} variant="body2" className='text-muted' style={{ fontSize: "14px" }}>
                                            Forgot password?
                                        </Link> */}
                                    </Grid>
                                    <Grid item>
                                        <Link to={"/signup"} variant="body2" className='text-muted' style={{ fontSize: "14px" }}>
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}
