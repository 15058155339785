import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import "./assets/css/style.css";
import "./assets/vendor/animate.css/animate.compat.css";
import "./assets/vendor/animate.css/animate.css";
import "./assets/vendor/animate.css/animate.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/animations.css";
import "./assets/vendor/boxicons/css/boxicons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/boxicons/css/transformations.css";
import "./assets/vendor/fontawesome-free/css/all.min.css"
import SignInSide from './pages/SignInSide';
import Signup from './pages/Signup';
import Test from './pages/Test';






function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<SignInSide />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/test' element={<Test />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
