import React from 'react'
import About from '../components/About'
import Contact from '../components/Contact'
import Faq from '../components/Faq'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Services from '../components/Services'
import Topbar from '../components/Topbar'
import Whyus from '../components/Whyus'

const Home = () => {
    return (
        <>
            <Topbar />
            <Navbar />
            <Hero />
            <Whyus />
            <About />
            <Services />
            <Faq />
            <Contact />
            <Footer />
        </>
    )
}

export default Home