import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Navbar = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("success");
    const [message, setMessage] = useState("message");

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    let fullName = localStorage.getItem("fullName");
    const logOut = () => {
        localStorage.clear();
        // window.location.href = "/login";
        setType("info");
        setMessage("You Are Logout SuccessFully");
        setOpen(true);
        setTimeout(() => { navigate('/login') }, 1000)
    };
    useEffect(() => {

        let authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }, []);

    return (
        <>

            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">

                    <h1 className="logo me-auto"><Link to={"/"}>Integrated Medicine</Link></h1>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                            <li><a className="nav-link scrollto" href="#about">About</a></li>
                            <li><a className="nav-link scrollto" href="#services">Services</a></li>
                            <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>

                    {isLoggedIn ? (
                        <nav id="navbar" className="navbar order-last order-lg-0">
                            <ul>
                                <li className="dropdown"><a href="#" className='ms-5' style={{ fontSize: "19px" }}> <i className='fa fa-user mt-1' style={{ fontSize: "17px" }}></i>&nbsp; <span>{fullName}</span> </a>
                                    <ul>
                                        <li><a href="#"> My Profile</a></li>
                                        <li><a href="#" onClick={logOut}>Logout</a></li>



                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    ) : (
                        <Link to="/login" className="appointment-btn scrollto">Login</Link>

                    )
                    }

                </div>
            </header>
        </>
    )
}

export default Navbar