import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="/">
                Integrated Medicine
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const Signup = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [fullName, setfullName] = useState("")
    const [mobile, setMobile] = useState("")
    const [area, setArea] = useState("")
    const [city, setCity] = useState("")
    const [state, setstate] = useState("")
    const [pincode, setpincode] = useState("")
    const [gender, setGender] = useState("")
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("success");
    const [message, setMessage] = useState("message");
    const [loading, setLoading] = useState(false);


    const key = 'updatable';

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };


    const submitUser = async (e) => {
        e.preventDefault()
        setLoading(true);
        const register = await fetch("https://med.rentproperties.in/register", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password, fullName: fullName, mobile: mobile, area: area, city: city, state: state, pincode: pincode, gender: gender })
        })
        let res = await register.json()
        try {

            if (register.status === 201) {
                setLoading(false);
                setType("success");
                setMessage("You Are Registered Successfully Now You Can Login");
                setOpen(true);
                setTimeout(() => { navigate('/login') }, 3000)

            } else {
                setLoading(false);
                let err = res[Object.keys(res)[0]] + [Object.keys(res)[0]][0]
                setError(err)
                setType("error");
                setMessage(err);
                setOpen(true);
            }
        }
        catch (err) {
            setLoading(false);
            console.log(error)
            setError(res[Object.keys(res)[0]] + [Object.keys(res)[0]][0])
            setType("error");
            setMessage(err.response);
            setOpen(true);
            setTimeout(() => { navigate('/signup') }, 3000)

        }
    }

    return (
        <>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: 'url(	http://localhost:3000/static/media/hero-bg.780df2f268b43ea6e3aa.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                {/* <LockOutlinedIcon />     */}
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign Up
                            </Typography>
                            <Box component="form" noValidate onSubmit={submitUser} sx={{ mt: 3 }} className="col-sm-11">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Full Name"
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={e => {
                                                setfullName(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                         
                                            onChange={e => {
                                                setEmail(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Phone Number"
                                            name="email"
                                         
                                            onChange={e => {
                                                setMobile(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="State"
                                            name="email"
                                      
                                            onChange={e => {
                                                setstate(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="City"
                                            name="email"
                                         
                                            onChange={e => {
                                                setCity(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Area"
                                            name="email"
                                           
                                            onChange={e => {
                                                setArea(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                            
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Pincode"
                                            name="email"
                                            type={"number"}
                                          
                                            onChange={e => {
                                                setpincode(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Password"
                                            name="email"
                                            type={"number"}
                                         
                                            onChange={e => {
                                                setPassword(e.target.value)
                                                console.log(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <label className='mb-2' style={{ fontSize: "13px" }}>Gender</label>
                                <div className="d-flex mb-2">

                                    <div class="form-check">
                                        <input class="form-check-input" value="Male" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={e => {
                                            setGender(e.target.value)
                                            console.log(e.target.value)
                                        }}
                                            required />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Male
                                        </label>
                                    </div>
                                    <div class="form-check ms-4">
                                        <input class="form-check-input" value="Female" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={e => {
                                            setGender(e.target.value)
                                            console.log(e.target.value)
                                        }}
                                            required />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            Female
                                        </label>
                                    </div>
                                </div>

                                </div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className='p-2'
                                    sx={{ mt: 3, mb: 2, backgroundColor: "#3291e6" }}

                                >
                                    {loading ? <><div class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>&nbsp;Loading...</> : <>Sign Up</>}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        {/* <Link to={"/"} variant="body2" className='text-muted'>
                                            Forgot password?
                                        </Link> */}
                                    </Grid>
                                    <Grid item>
                                        <Link to={"/login"} variant="body2" className='text-muted'>
                                            {"Don't have an account? Sign In"}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}

export default Signup